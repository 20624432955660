section.side {
  width: 200px;
  position: fixed;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

section.side>div {
  padding: 10px;
  margin: 5px;
  border-radius: 3px;
  border: 1px solid;
  cursor: pointer;
  user-select: none;
}

.patch-list {
  display: flex;
  flex-direction: column;
  margin-left: 200px;
}