body {
  margin: 0;
  font-size: 14px;
  font-family: 'Source Code Pro', monospace;
  background-color: var(--background-color);
  color: var(--text-color);
}

:root {
  --background-color: #1A1A1A;
  --primary-color: #8B5CF6;
  --secondary-color: #FFC107;
  --accent-color: #E91E63;
  --text-color: #FFFFFF;
  --subtext-color: #BDBDBD;

  /* Code diff colors */
  --added-code-color: #204929;
  --deleted-code-color: #492a2a;
  --unchanged-code-color: #2b2b2b;
  --diff-text-color: #d8d8d8;
}

a {
  text-decoration: initial;
  color: inherit;
}

a:hover {
  text-decoration: underline;
}

textarea, input {
  background-color: var(--unchanged-code-color);
  color: var(--diff-text-color);
  border: 0;
  border-radius: 5px;
  width: stretch;
  padding: 5px;
}

.normal {
  background-color: var(--unchanged-code-color);
  color: var(--diff-text-color);
}

.add {
  background-color: var(--added-code-color);
  color: var(--diff-text-color);
}

.del {
  background-color: var(--deleted-code-color);
  color: var(--diff-text-color);
}