.new-patch {
    padding: 20px;
    display: flex;
    flex-direction: row;
}

.new-patch>div {
    flex: 1;
    height: calc(100vh - 40px);
    overflow: auto;
}