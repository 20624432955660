.patch-header {
    display: grid;
    grid-auto-columns: 150px auto 150px 80px;
    grid-auto-flow: column;
    width: stretch;
    white-space: nowrap;
    line-height: 2em;
    margin: 5px;
    border-radius: 3px;
    border: 1px solid;
    cursor: pointer;
}

.patch-header>div {}

.patch-header>diV:nth-child(1) {
    background-color: var(--primary-color);
    text-align: center;
    display: flex;
    align-content: center;
}

.patch-header>diV:nth-child(1) img {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    margin-right: 5px;
}

.patch-header>div:nth-child(2) {}

.patch-header>div:nth-child(3) {
    background-color: var(--primary-color);
    padding-left: 10px;
}

.patch-header>div:nth-child(4) {
    background-color: var(--primary-color);
    text-align: right;
    padding-right: 10px;
}